<template>
  <div class="business">
    <!-- banner部分 -->
    <div class="business-banner">
      <div class="business-bannerbox">
        <div class="business-leftbox">
          <div class="business-contentbox">
            <div class="business-title family-title">业财一体系统</div>
            <div class="business-miaosu family-text">
              系统将实现业务与财务数据流的闭环管理。将企业经营中的,业务流程，财务流程，管理流程有机融合，提升企业不同部门的协作效率和管理水平，降低跨部门协作难度的同时,也为企业积累核心数据资产，加强企业的竞争力。
            </div>
            <div class="business-shen ">
              <div class="bus-ansen family-picturetext" @click="Busyon">
                申请体验
              </div>
             
            </div>
          </div>
        </div>
        <div class="business-rightbox">
          <div class="business-imgbox">
            <img
              src="https://qncdn.uni-sheep.com/s/Group%20837.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 业财一体问题 -->
    <div class="problem-box">
      <div class="problem-centerbox">
        <div class="problem-top">
          <div class="problem-title family-title ">业财一体问题</div>
          <div class="problem-secon family-text">业财一体包含了哪些问题</div>
        </div>
        <div class="problem-bottom">
          <img
            src="https://qncdn.uni-sheep.com/s/Group%20481%20%281%29%281%29.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 业财一体模块 -->
    <div class="business-modelbox">
      <div class="model-center" v-if="businessdisplay">
        <div class="model-top">
          <div class="model-title family-title">业财一体模块</div>
          <div class="model-secon family-text">业财一体包含了哪些模块</div>
        </div>
        <div class="model-bottom">
          <img src="https://qncdn.uni-sheep.com/s/Group%20430%281%29.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 业财一体流程 -->
    <div class="flowpath-box">
      <div class="flowpath-cenbox" v-if="flowpathdisplay">
        <div class="flowpath-topbox">
          <div class="flowpath-title family-title">业财一体流程</div>
          <div class="flowpath-secon family-text">业财一体打通了哪些流程</div>
        </div>
        <div class="flowpath-bottombox">
          <img
            src="https://qncdn.uni-sheep.com/s/%E7%94%BB%E6%9D%BF%E5%A4%87%E4%BB%BD%2019%201.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 业财一体图 -->
    <div class="integrated-box">
      <div class="integrated-centerbox" v-if="integrateddisplay">
        <div class="integrated-topbox">
          <div class="integrated-title family-title">业财一体图</div>
          <div class="integrated-secon family-text">业财一体包含了哪些模块</div>
        </div>
        <div class="integrated-bottombox">
          <div class="inte-firstbox">
            <div class="inte-ftit">在手资产及负债明细：</div>
            <div class="inte-fimg">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20465.png"
                alt=""
              />
            </div>
          </div>
          <div class="inte-secondbox">
            <div class="inte-ftit">应收应付明细：</div>
            <div class="inte-fimg">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20466.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公共脚步部分 -->
    <public-footer @Immediately="Immediately"></public-footer>
  </div>
</template>
<script>

import PublicFooter from "../../../components/publicfooter/index.vue";

export default {
  metaInfo: {
    title: '业财一体系统-自牧天成科技发展有限公司',
    // meta: [
    //   {
    //     name: 'description',
    //     content: 'This is my page description'
    //   }
    // ]
  },
  components: {
    PublicFooter,
  },
  props: [],
  data() {
    return {
      // 业财一体模块动画
      businessdisplay: false,
      //业财一体流程动画
      flowpathdisplay: false,
      //业财一体图动画
      integrateddisplay: false,
    };
  },
  methods: {
    // 立即咨询
    Immediately() {
      this.$router.push("/fillinformation");
    },
    // 申请体验
    Busyon(){
      this.$router.push("/fillinformation");
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取浏览器滚动条高度
    handleScroll(event) {
      // console.log("滚动条高度为：", event.target.scrollTop);
      this.scrollTop = event.target.scrollTop;
      const topObj = {
        300: "businessdisplay",
        900: "flowpathdisplay",
        1900: "integrateddisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
  },
  created() {
    this.listenerFunction();
  },
  beforeDistroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
 
};
</script>
<style scoped lang="scss">

// 公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.business {
  width: 100%;
  height: 100%;
  //banner部分
  .business-banner {
    width: 100%;
    height: 38rem;
    background: #eef3fe;
    .business-bannerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .business-leftbox {
        width: 35%;
        height: 100%;
        display: flex;
        align-items: center;
        .business-contentbox {
          width: 22rem;
          height: 18rem;
          display: flex;
          flex-direction: column;
          .business-title {
            font-size: 2rem;
            font-weight: 800;
            padding-left: 0.5rem;
          }
          .business-miaosu {
            width: 100%;
            height: 8rem;
            line-height: 2rem;
            font-size: 1rem;
            color: #666666;
            margin-top: 1.5rem;
          }
          .business-shen {
            width: 100%;
            height: 3rem;
             margin-top: 3rem;
            .bus-ansen{
              width: 142px;
              height: 49px;
              background: #3371FF;
              text-align: center;
              line-height:49px ;
              color:white;
              border-radius: 5px;
              cursor: pointer;
            }
            .bus-ansen:hover{
              background: blue;
            }
          }
        }
      }
      .business-rightbox {
        width: 94%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content:flex-end;
        .business-imgbox {
          width: 55.2rem;
          height: 32rem;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  // 第二部分
  .problem-box {
    width: 100%;
    height: 45rem;
    .problem-centerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      .problem-top {
        width: 16rem;
        height: 4.5rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
        .problem-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .problem-secon {
          font-size: 1.3rem;
          color: #353a67;
          text-align: center;
        }
      }
      .problem-bottom {
        width: 100%;
        height: 32rem;
        margin-top: 3rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 业财一体模块
  .business-modelbox {
    width: 100%;
    height: 46rem;
    background: #f8f9fe;
    .model-center {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .model-top {
        width: 16rem;
        height: 4.5rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
        .model-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .model-secon {
          font-size: 1.3rem;
          color: #353a67;
          text-align: center;
        }
      }
      .model-bottom {
        width: 100%;
        height: 32.4375rem;
        margin-top: 3rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 业财一体流程
  .flowpath-box {
    width: 100%;
    height: 68rem;
    .flowpath-cenbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .flowpath-topbox {
        width: 16rem;
        height: 4.5rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.5rem;
        .flowpath-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .flowpath-secon {
          font-size: 1.3rem;
          color: #353a67;
          text-align: center;
        }
      }
      .flowpath-bottombox {
        width: 100%;
        height: 58.5rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  //业财一体图
  .integrated-box {
    width: 100%;
    height: 49.5rem;
    background: #f8f9fe;
    .integrated-centerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .integrated-topbox {
        width: 16rem;
        height: 4.5rem;

        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 3rem;
        .integrated-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .integrated-secon {
          font-size: 1.3rem;
          color: #353a67;
          text-align: center;
        }
      }
      .integrated-bottombox {
        width: 100%;
        height: 33.125rem;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .inte-firstbox {
          width: 100%;
          height: 17.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .inte-ftit {
            font-size: 1.25rem;
            font-weight: 800;
          }
          .inte-fimg {
            width: 100%;
            height: 15rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .inte-secondbox {
          width: 100%;
          height: 14.375rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .inte-ftit {
            font-size: 1.25rem;
            font-weight: 800;
          }
          .inte-fimg {
            width: 100%;
            height: 11.8125rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
