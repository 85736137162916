var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business"},[_c('div',{staticClass:"business-banner"},[_c('div',{staticClass:"business-bannerbox"},[_c('div',{staticClass:"business-leftbox"},[_c('div',{staticClass:"business-contentbox"},[_c('div',{staticClass:"business-title family-title"},[_vm._v("业财一体系统")]),_c('div',{staticClass:"business-miaosu family-text"},[_vm._v(" 系统将实现业务与财务数据流的闭环管理。将企业经营中的,业务流程，财务流程，管理流程有机融合，提升企业不同部门的协作效率和管理水平，降低跨部门协作难度的同时,也为企业积累核心数据资产，加强企业的竞争力。 ")]),_c('div',{staticClass:"business-shen"},[_c('div',{staticClass:"bus-ansen family-picturetext",on:{"click":_vm.Busyon}},[_vm._v(" 申请体验 ")])])])]),_vm._m(0)])]),_vm._m(1),_c('div',{staticClass:"business-modelbox"},[(_vm.businessdisplay)?_c('div',{staticClass:"model-center"},[_vm._m(2),_vm._m(3)]):_vm._e()]),_c('div',{staticClass:"flowpath-box"},[(_vm.flowpathdisplay)?_c('div',{staticClass:"flowpath-cenbox"},[_vm._m(4),_vm._m(5)]):_vm._e()]),_c('div',{staticClass:"integrated-box"},[(_vm.integrateddisplay)?_c('div',{staticClass:"integrated-centerbox"},[_vm._m(6),_vm._m(7)]):_vm._e()]),_c('public-footer',{on:{"Immediately":_vm.Immediately}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-rightbox"},[_c('div',{staticClass:"business-imgbox"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20837.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problem-box"},[_c('div',{staticClass:"problem-centerbox"},[_c('div',{staticClass:"problem-top"},[_c('div',{staticClass:"problem-title family-title"},[_vm._v("业财一体问题")]),_c('div',{staticClass:"problem-secon family-text"},[_vm._v("业财一体包含了哪些问题")])]),_c('div',{staticClass:"problem-bottom"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20481%20%281%29%281%29.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model-top"},[_c('div',{staticClass:"model-title family-title"},[_vm._v("业财一体模块")]),_c('div',{staticClass:"model-secon family-text"},[_vm._v("业财一体包含了哪些模块")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model-bottom"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20430%281%29.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flowpath-topbox"},[_c('div',{staticClass:"flowpath-title family-title"},[_vm._v("业财一体流程")]),_c('div',{staticClass:"flowpath-secon family-text"},[_vm._v("业财一体打通了哪些流程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flowpath-bottombox"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/%E7%94%BB%E6%9D%BF%E5%A4%87%E4%BB%BD%2019%201.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integrated-topbox"},[_c('div',{staticClass:"integrated-title family-title"},[_vm._v("业财一体图")]),_c('div',{staticClass:"integrated-secon family-text"},[_vm._v("业财一体包含了哪些模块")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integrated-bottombox"},[_c('div',{staticClass:"inte-firstbox"},[_c('div',{staticClass:"inte-ftit"},[_vm._v("在手资产及负债明细：")]),_c('div',{staticClass:"inte-fimg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20465.png","alt":""}})])]),_c('div',{staticClass:"inte-secondbox"},[_c('div',{staticClass:"inte-ftit"},[_vm._v("应收应付明细：")]),_c('div',{staticClass:"inte-fimg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20466.png","alt":""}})])])])
}]

export { render, staticRenderFns }